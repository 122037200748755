
import { AsyncView } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { Listing } from 'client-website-ts-library/types';
import { Component, Mixins } from 'vue-property-decorator';

import Form from '@/components/Forms/Form.vue';
import { FormConstructorData } from 'client-website-ts-library/types/Forms';
import { ContextItemType } from 'client-website-ts-library/types/Context';

@Component({
  components: {
    Form,
  },
})
export default class ListingTenanacyAgreement extends Mixins(AsyncView) {
  private listing: Listing | null = null;

  private formData: FormConstructorData | null = null;

  mounted() {
    API.Listings.Get(this.$route.params.id).then((listing) => {
      this.listing = listing;

      this.formData = {
        ClientWebsiteId: Config.Website.Id,
        Items: [
          {
            Type: ContextItemType.Listing,
            Id: listing.Id,
          },
        ],
      };
    });
  }
}
